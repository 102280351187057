import { Box, Fade, Modal, Typography } from "@mui/material";
import { Button, Title } from "../../atoms";
interface RisksModalProps {
  open: boolean;
  onClose: () => void;
  risksList: { percentage: number; name: string }[] | undefined;
}

export function RisksModal(props: RisksModalProps) {
  const { open, onClose, risksList } = props;

  return (
    <Modal onClose={() => onClose()} open={open}>
      <Fade in={open}>
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "375px",
              margin: "0 auto",
              padding: "20px",
            }}
          >
            <Title>Список рисков</Title>
            <Box sx={{ display: "grid", gap: "2.25rem" }}>
              {risksList?.map((risk) => (
                <Box sx={{ display: "grid", gap: "5px" }}>
                  <Typography sx={{ fontSize: "2.25rem" }}>
                    {risk.percentage}%
                  </Typography>
                  <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>
                    {risk.name}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Button
              onClick={() => {
                onClose();
              }}
              sx={{
                marginTop: "2rem",

                border: "1px solid #472B2B",
                backgroundImage:
                  "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
                ":hover": {
                  background: "unset",
                  backgroundImage:
                    "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
                },
              }}
            >
              Закрыть список
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
