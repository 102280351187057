export const BackgroundGradient = () => (
  <svg
    width="430"
    height="57"
    viewBox="0 0 430 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="215"
      cy="0.5"
      rx="215"
      ry="56.5"
      fill="url(#paint0_radial_2_196)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2_196"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(214.3 0.499997) scale(215.7 56.684)"
      >
        <stop stopColor="#579883" />
        <stop offset="1" stopColor="#2B473E" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const InfoIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="7" r="6.5" stroke="#E3BB51" />
    <path d="M7 3V8M7 9V11" stroke="#E3BB51" />
  </svg>
);

export const SuccessfullRequestIcon = () => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="29.5" cy="29.5" r="28.5" stroke="#00FFAD" stroke-width="2" />
    <path
      d="M16.8572 29.5L25.2857 37.9286L42.1429 21.0714"
      stroke="#00FFAD"
      stroke-width="2"
    />
  </svg>
);

export const FailedExhangeRequestIcon = () => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="29.5" cy="29.5" r="28.5" stroke="#FF0000" stroke-width="2" />
    <path
      d="M16.8571 42.1429L42.1429 16.8571M42.1429 42.1429L16.8571 16.8571"
      stroke="#FF0000"
      stroke-width="2"
    />
  </svg>
);

export const ProcessingRequestIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="7" r="6.5" stroke="#51C0FF" />
    <path
      d="M10 4H4L6 7L4 10H10L8 7L10 4Z"
      stroke="#51C0FF"
      strokeLinejoin="round"
    />
  </svg>
);

export const SuccessfullyAppliedIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="7" r="6.5" stroke="#00FFAD" />
    <path d="M4 7L6 9L10 5" stroke="#00FFAD" />
  </svg>
);

export const DeclinedRequestIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7" cy="7" r="6.5" stroke="#FF0000" />
    <path d="M4 10L10 4M10 10L4 4" stroke="#FF0000" />
  </svg>
);
