import { Box, Typography } from "@mui/material";
import { Button } from "../../../components/atoms";
import { FailedExhangeRequestIcon } from "../../../icons";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../../hooks/useTelegram";

export function FailedAmlCheck() {
  const navigate = useNavigate();
  const { tg } = useTelegram();

  return (
    <Box
      sx={{
        maxWidth: "375px",
        margin: "0 auto",
        width: "100%",
        paddingInline: "20px",
        minHeight: "75vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FailedExhangeRequestIcon />
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "700",
            textTransform: "uppercase",
            textAlign: "center",
            paddingBottom: "10px",
            paddingTop: "1.25rem",
          }}
        >
          Проверка не пройдена
        </Typography>
        <Typography sx={{ textAlign: "center", fontSize: "15px" }}>
          Повторите попытку позже
        </Typography>
      </Box>

      <Button
        onClick={() => {
          navigate("/");
        }}
        sx={{
          marginTop: "1.25rem",
          border: "1px solid #472B2B",
          backgroundImage:
            "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
          ":hover": {
            background: "unset",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
          },
        }}
      >
        Перейти на главный экран
      </Button>
    </Box>
  );
}
