import { Box, Typography } from "@mui/material";
import {
  DeclinedRequestIcon,
  ProcessingRequestIcon,
  SuccessfullyAppliedIcon,
} from "../../icons";
import { Order } from "../../services/orders/history/interface";

interface ApplicationCardProps {
  orderData: Order;
}

export function ApplicationCard({ orderData }: ApplicationCardProps) {
  return (
    <Box sx={{ display: "" }}>
      {orderData.status === "in_progress" ? (
        <Box sx={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
          <ProcessingRequestIcon />
          <Box>
            <Typography sx={{ fontWeight: "700" }}>
              {orderData.type === "buy"
                ? "Заявка на продажу в обработке"
                : "Заявка на покупку в обработке"}
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
              Номер заявки: {orderData.order_id}
            </Typography>
            {orderData.type === "buy" && (
              <Typography sx={{ fontSize: "12px" }}>
                Адрес кошелька: {orderData.wallet}
              </Typography>
            )}
            {orderData.type === "sell" && (
              <Typography sx={{ fontSize: "11px" }}>
                Сумма: {orderData.value} RUB.
              </Typography>
            )}
          </Box>
        </Box>
      ) : orderData.status === "closed" ? (
        <Box sx={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
          <SuccessfullyAppliedIcon />
          <Box>
            <Typography sx={{ fontWeight: "700" }}>
              {orderData.type === "buy"
                ? "Успешная заявка на продажу"
                : "Успешная заявка на покупку"}
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
              Номер заявки: {orderData.order_id}
            </Typography>
            {orderData.type === "sell" && (
              <Typography sx={{ fontSize: "10px" }}>
                Адрес кошелька: {orderData.wallet}
              </Typography>
            )}
            <Typography sx={{ fontSize: "10px" }}>
              {orderData.type === "buy"
                ? `Получено: ${orderData.value} RUB по курсу ${orderData.rate}`
                : `Отправлено: ${orderData.value} RUB по курсу ${orderData.rate}`}
            </Typography>
          </Box>
        </Box>
      ) : orderData.status === "cancelled" ? (
        <Box sx={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
          <DeclinedRequestIcon />

          <Box>
            <Typography sx={{ fontWeight: "700" }}>Заявка отклонена</Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
              Номер заявки: {orderData.order_id}{" "}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
