import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../../hooks/useTelegram";
import { FailedExhangeRequestIcon } from "../../../icons";
import { Button } from "../../../components/atoms";

export function FailedExhangeRequest() {
  const navigate = useNavigate();
  const { tg } = useTelegram();

  return (
    <Box
      sx={{
        maxWidth: "375px",
        margin: "0 auto",
        width: "100%",
        paddingInline: "20px",
        minHeight: "75vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FailedExhangeRequestIcon />
        <Typography
          sx={{
            fontSize: "21px",
            fontWeight: "700",
            textTransform: "uppercase",
            textAlign: "center",
            paddingBottom: "10px",
            paddingTop: "1.25rem",
          }}
        >
          Заявка не создана
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          Обратитесь в поддержку или повторите попытку
        </Typography>
      </Box>

      <Box sx={{ display: "grid", gap: "15px", width: "100%" }}>
        <Button
          onClick={() => {
            tg.openTelegramLink("https://t.me/Mosca67_Support");
            tg.close();
          }}
          sx={{
            border: "1px solid #472B2B",
            marginTop: "1.75rem",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
            ":hover": {
              background: "unset",
              backgroundImage:
                "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
            },
          }}
        >
          Поддержка
        </Button>
        <Button
          onClick={() => {
            navigate("/");
          }}
          sx={{
            border: "1px solid #472B2B",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
            ":hover": {
              background: "unset",
              backgroundImage:
                "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
            },
          }}
        >
          Перейти на главный экран
        </Button>
      </Box>
    </Box>
  );
}
