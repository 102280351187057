import { useNavigate } from "react-router-dom";
import { Title, Button } from "../../components/atoms";
import { useTelegram } from "../../hooks/useTelegram";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { TabContext } from "@mui/lab";
import { ExchangeForm } from "../../components/";
import { RulesModal } from "../../components/modals";

export function Exchange() {
  const [openRulesModal, setOpenRulesModal] = useState<boolean>(false);
  const [value, setValue] = useState("1");

  const navigate = useNavigate();
  const { tg } = useTelegram();
  tg.BackButton.show();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          opacity: openRulesModal ? "0" : "1", // HIDING CONTENT SO THAT BACKGROUND VIDEO GOES ON WHILE RULES MODAL BEING OPEN
          position: "relative",
          zIndex: "999",
          marginBottom: "50vh",
        }}
      >
        <Title>Обмен</Title>

        {/* Tabs */}
        <TabContext value={value}>
          <Box
            sx={{
              border: "1px solid #00FFAD1A",
              borderRadius: "14px",
            }}
          >
            <Tabs
              sx={{
                ".MuiTabs-flexContainer": {
                  justifyContent: "space-between",
                },
                margin: "0 auto",
                color: "#fff",
                padding: "3px",
                minHeight: "unset",
              }}
              onChange={handleChange}
            >
              <Tab
                value="1"
                sx={{
                  background: value === "1" ? "#00FFAD1A" : "transparent",
                  color: "#fff",
                  width: "49%",
                  borderRadius: "10px",
                  fontWeight: "700",

                  minHeight: "unset",
                  paddingInline: "10px",

                  transition: "all .2s ease",
                }}
                label="Купить USDT"
              />
              <Tab
                value="2"
                sx={{
                  background: value === "2" ? "#00FFAD1A" : "transparent",
                  color: "#fff",
                  width: "49%",
                  borderRadius: "10px",
                  fontWeight: "700",
                  minHeight: "unset",
                  paddingInline: "10px",
                }}
                label="Продать USDT"
              />
            </Tabs>
          </Box>

          <ExchangeForm
            toggleRulesModal={() => setOpenRulesModal((prev) => !prev)}
            tab={value}
          />
        </TabContext>

        <Button
          onClick={() => {
            navigate("/");
            tg.BackButton.hide();
          }}
          sx={{
            marginTop: "1rem",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            ":hover": {
              background: "unset",
              backgroundImage:
                "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            },
          }}
        >
          Перейти на главный экран
        </Button>
      </Box>

      <RulesModal
        open={openRulesModal}
        onClose={() => setOpenRulesModal(false)}
      />
    </>
  );
}
