import { api } from "../../api";
import { AmlCheckWalletRequest, AmlCheckWalletResponse } from "./interface";

export async function AmlCheckWallet({ trc20_wallet }: AmlCheckWalletRequest) {
  const access_token = localStorage.getItem("access_token") || "";

  try {
    const res = await api.post<AmlCheckWalletResponse>(
      `/aml/check`, // URL without query params
      null, // No data to send in POST body
      {
        headers: {
          "access-token": access_token,
        },
        params: {
          trc20_wallet, // Pass query parameters using `params` option
        },
      }
    );

    return res;
  } catch (error) {
    console.error("Error checking AML wallet:", error);
    throw error;
  }
}
