import { api } from "../../api";
import { QuotaResponse } from "./interface";

export async function getAmlQuota() {
  const access_token = localStorage.getItem("access_token") || "";

  const res = await api.get<QuotaResponse>("/aml/quota", {
    headers: {
      "access-token": access_token,
    },
  });

  return res;
}
