import { Box, Typography } from "@mui/material";
import { Title, Button } from "../../components/atoms";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../hooks/useTelegram";

export function About() {
  const { tg } = useTelegram();
  tg.BackButton.show();
  const navigate = useNavigate();

  return (
    <Box>
      <Title>О нас</Title>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          lineHeight: "1.25",
          fontSize: "15px",
        }}
      >
        <Typography
          sx={{
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          Занимаемся обменом криптовалют более 3х лет.
        </Typography>
        <Typography
          sx={{
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          Работаем 24/7. При первом визите необходимо иметь паспорт.
        </Typography>
        <Typography
          sx={{
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          Работаем только за наличные рубли.
        </Typography>

        <Typography
          sx={{
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          Мы работаем стол в стол, т.е. не храним ваши средства у себя.
        </Typography>

        <Typography
          sx={{
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          У нас вы можете купить USDT без комиссии по самому лучшему курсу в
          Москве.
        </Typography>
        <Typography
          sx={{
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          Наш адрес: <br />
          г. Москва, Пресненская набережная 12, Башня Федерация. Восток, этаж 11
        </Typography>
        <Typography
          sx={{
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          Для получения пропуска к нам в офис и покупки USDT, вам нужно создать
          заявку через бота
        </Typography>
        <Typography
          sx={{
            fontSize: "inherit",
            lineHeight: "inherit",
          }}
        >
          Гостевой пропуск выдается на ресепшене:
          <br />
          На минус первом этаже с 8.00 до 19.00
          <br />
          На первом этаже c 19.00 до 8.00.
        </Typography>
      </Box>

      <Button
        onClick={() => {
          navigate("/");
          tg.BackButton.hide();
        }}
        sx={{
          marginTop: "1.75rem",

          backgroundImage:
            "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          ":hover": {
            background: "unset",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          },
        }}
      >
        Перейти на главный экран
      </Button>
    </Box>
  );
}
