import { Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../../components/atoms";
import { SuccessfullRequestIcon } from "../../../icons";

export function SuccessfullBuyReq() {
  const [searchParamas] = useSearchParams();
  const orderId = searchParamas.get("order-id");
  const firstName = searchParamas.get("first-name");
  const lastName = searchParamas.get("last-name");
  const patronymic = searchParamas.get("patronymic");

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "75vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SuccessfullRequestIcon />
        <Typography
          sx={{
            fontSize: "21px",
            fontWeight: "700",
            textTransform: "uppercase",
            textAlign: "center",
            paddingBottom: "10px",
            paddingTop: "1.25rem",
          }}
        >
          Заявка создана
        </Typography>
        <Typography>
          Номер заявки на покупку{" "}
          <span style={{ fontSize: "15px", fontWeight: "500" }}>
            {orderId}.
          </span>
        </Typography>
        <Typography
          sx={{ textAlign: "center", paddingTop: ".75rem", fontSize: "14px" }}
        >
          Пропуск на имя {firstName} {lastName} {patronymic} заказан.
        </Typography>
        <Typography
          sx={{ textAlign: "center", paddingTop: ".75rem", fontSize: "14px" }}
        >
          Наш адрес: Москва, Пресненская набережная 12, Башня федерация Восток,
          этаж 11
        </Typography>
        <Typography
          sx={{ textAlign: "center", paddingTop: ".75rem", fontSize: "14px" }}
        >
          Гостевой пропуск выдается на ресепшене
        </Typography>
        <Typography
          sx={{ textAlign: "center", paddingTop: ".75rem", fontSize: "14px" }}
        >
          Для получения гостевого пропуска при себе необходимо иметь документ
          удостоверяющий личность (паспорт, водительское удостоверение).
        </Typography>
      </Box>
      <Button
        onClick={() => {
          navigate("/");
        }}
        sx={{
          marginTop: "2.75rem",
          backgroundImage:
            "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          ":hover": {
            background: "unset",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          },
        }}
      >
        Перейти на главный экран
      </Button>
    </Box>
  );
}
