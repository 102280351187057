import { Typography } from "@mui/material";
import { Title, Button } from "../../components/atoms";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../hooks/useTelegram";

export function ReferralProgram() {
  const { tg } = useTelegram();
  const navigate = useNavigate();

  return (
    <>
      <Title>Реферальная программа</Title>
      <Typography>
        Реферельная программа находится в разработке и скоро будет доступна.
      </Typography>
      <Button
        onClick={() => {
          navigate("/");
          tg.BackButton.hide();
        }}
        sx={{
          marginTop: "1.75rem",
          backgroundImage:
            "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          ":hover": {
            background: "unset",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          },
        }}
      >
        Перейти на главный экран
      </Button>
    </>
  );
}
