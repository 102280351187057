import { Typography as MuiTypography, TypographyProps } from "@mui/material";

interface ITextFieldProps extends TypographyProps {}

export const Title: React.FC<ITextFieldProps> = (props) => {
  return (
    <MuiTypography
      {...props}
      sx={{
        position: "relative",
        zIndex: "999",

        fontSize: "1.5rem",
        fontWeight: "700",
        textTransform: "uppercase",
        paddingBottom: "1.75rem",
        ...props.sx,
      }}
    />
  );
};
