import { Box, Fade, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Button } from "../atoms";

interface Props {
  status: string;
  address: string;
  blockchain: string;
  cluster: string;
  first_transaction: string;
  last_transaction: string;
  risk: number;
  risks_list: { percentage: number; name: string }[];
  transactions_count: number;

  openModal: () => void;
}
export function AmlVerificationResponse(data: Props) {
  const {
    cluster,
    address,
    risks_list,
    first_transaction,
    last_transaction,
    transactions_count,
    status,
    risk,
    blockchain,
    openModal,
  } = data;

  const highRisk = risks_list.length > 0;

  // FORMATTING DATE TO RUSSIAN FORMAT // Mar 13, 2023 02:04 PM => 13 марта 2023 г. в 14:04
  function formatToRussianDate(dateStr: string) {
    return new Date(Date.parse(dateStr))
      .toLocaleString("ru-RU", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(",", " г. в");
  }

  return (
    <Fade in>
      <Box>
        {highRisk ? (
          <ErrorOutlineOutlinedIcon
            sx={{ fontSize: "2rem", color: "#FFC300", marginBottom: ".5rem" }}
          />
        ) : (
          <CheckCircleOutlineIcon
            sx={{ fontSize: "2rem", color: "#00FFAD", marginBottom: ".5rem" }}
          />
        )}

        <Typography
          sx={{
            fontSize: "20px",
            color: highRisk ? "#FFC300" : "#00FFAD",
            textTransform: "uppercase",
            fontWeight: "600",
          }}
        >
          {highRisk
            ? `Обнаружены риски (${risks_list.length})`
            : "Рисков не обнаружено"}
        </Typography>

        <Box
          sx={{
            marginTop: "1.25rem",
            display: "grid",
            gridTemplateColumns: "50% 50%",
            rowGap: "1.5rem",
            columnGap: "0rem",
          }}
        >
          <Box sx={{ display: "flex", gap: "25px", flexDirection: "column" }}>
            <Typography>
              Статус:{" "}
              <Box
                component="span"
                sx={{
                  color: status === "Active" ? "#00FFAD" : "error.main",
                  textTransform: "capitalize",
                }}
              >
                {status}
              </Box>
            </Typography>
            <Typography>Блокчейн: {blockchain}</Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "25px", flexDirection: "column" }}>
            <Typography>
              Риск:{" "}
              <Box
                component="span"
                sx={{ color: risk > 50 ? "warning.main" : "unset" }}
              >{`${risk}%`}</Box>
            </Typography>
            <Typography>Кластер: {cluster}</Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "5px", alignItems: "baseline" }}>
            <Typography>Адрес:</Typography>
            <Typography sx={{ fontSize: "12.5px" }}>{address}</Typography>
          </Box>
        </Box>

        {highRisk && (
          <Button
            onClick={() => openModal()}
            sx={{
              border: "1px solid #472B2B",
              marginTop: "1.75rem",
              backgroundImage:
                "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
              ":hover": {
                background: "unset",
                backgroundImage:
                  "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)",
              },
            }}
          >
            Открыть список
          </Button>
        )}

        <Box sx={{ marginTop: "1.85rem", display: "grid", gap: "1.25rem" }}>
          <Box sx={{ display: "grid", gap: "5px" }}>
            <Typography>Последняя транзакция:</Typography>
            <Typography sx={{ fontSize: "20px" }}>
              {formatToRussianDate(last_transaction)}
            </Typography>
          </Box>

          <Box sx={{ display: "grid", gap: "5px" }}>
            <Typography>Первая транзакция:</Typography>
            <Typography sx={{ fontSize: "20px" }}>
              {formatToRussianDate(first_transaction)}
            </Typography>
          </Box>

          <Box sx={{ display: "grid", gap: "5px" }}>
            <Typography>Колличество транзакций:</Typography>
            <Typography sx={{ fontSize: "20px" }}>
              {transactions_count}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}
