import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { RootLayout } from "./components";

import { Home } from "./pages";
import { About } from "./pages/about";
import { Rate } from "./pages/rate";
import { Exchange } from "./pages/exchange";
import { HistoryPage } from "./pages/history";
import { Support } from "./pages/support";
import { AMLVerification } from "./pages/aml-verification";
import { ReferralProgram } from "./pages/referral-program";

import {
  SuccessfullBuyReq,
  SuccessfullSoldReq,
} from "./pages/request-status-page/success";

import {
  FailedExhangeRequest,
  FailedAmlCheck,
} from "./pages/request-status-page/failed";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/valuation",
          element: <Rate />,
        },
        {
          path: "/aml-verification",
          element: <AMLVerification />,
        },
        {
          path: "/referral-program",
          element: <ReferralProgram />,
        },
        {
          path: "/exchange",
          element: <Exchange />,
        },
        {
          path: "/requests",
          element: <HistoryPage />,
        },
        {
          path: "/support",
          element: <Support />,
        },
        {
          path: "/successfull-buy-req",
          element: <SuccessfullBuyReq />,
        },
        {
          path: "/successfull-sold-req",
          element: <SuccessfullSoldReq />,
        },
        {
          path: "/failed-exchange-req",
          element: <FailedExhangeRequest />,
        },
        {
          path: "/failed-aml-check",
          element: <FailedAmlCheck />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
