import { Alert, Box, Typography } from "@mui/material";
import { InfoIcon } from "../../icons";
import { Title, Button } from "../../components/atoms";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../hooks/useTelegram";
import { useQuery } from "react-query";
import { getCurrencyRate } from "../../services/rate";

export function Rate() {
  const { tg } = useTelegram();
  const navigate = useNavigate();
  tg.BackButton.show();

  const access_token = localStorage.getItem("access_token") || "";

  const { data, isLoading } = useQuery(
    "currency-rate",
    () => getCurrencyRate(access_token),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: 60000, // Refetching once a minute
    }
  );

  return (
    <>
      <Title>Курс USDT</Title>
      <Box sx={{ paddingBottom: "20px" }}>
        <Typography sx={{ fontWeight: 300 }}>Покупка</Typography>
        <Typography sx={{ fontSize: "32px" }}>
          {isLoading ? "..." : `${data?.data.sell || "-"} RUB`}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 300 }}>Продажа</Typography>
        <Typography sx={{ fontSize: "32px" }}>
          {isLoading ? "..." : `${data?.data.buy || "-"} RUB`}
        </Typography>
      </Box>

      <Alert
        variant="outlined"
        severity="info"
        sx={{
          color: "#E3BB51",
          border: "none",
          alignItems: "center",
          paddingTop: "2.75rem",
          paddingInline: "0",
        }}
        iconMapping={{ info: <InfoIcon /> }}
      >
        Данный курс является биржевым и меняется каждую минуту.{" "}
      </Alert>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingTop: "20px",
        }}
      >
        <Typography>
          Мы не берем никаких дополнительных комиссий с Вас.
        </Typography>
      </Box>
      <Button
        onClick={() => {
          navigate("/");
          tg.BackButton.hide();
        }}
        sx={{
          marginTop: "1.75rem",
          backgroundImage:
            "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          ":hover": {
            background: "unset",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          },
        }}
      >
        Перейти на главный экран
      </Button>
    </>
  );
}
