import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  attemptsLeft: number | undefined;
  upcomingRestartDate: string | undefined;
}

// FUNCTION FOR DISPLAYING RIGHT FORM OF WORD
// const getAttemptWord = (attempts: number): string => {
//   if (attempts === 1) return "попытка";
//   if (attempts > 1 && attempts < 5) return "попытки";
//   return "попыток";
// };

export function AttemptMessage({ attemptsLeft, upcomingRestartDate }: Props) {
  const upcomingDate =
    upcomingRestartDate &&
    new Date(upcomingRestartDate).toLocaleString("ru-RU", {
      day: "numeric",
      month: "numeric",
      year: "2-digit",
    });

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "11px",
        }}
      >
        {attemptsLeft && attemptsLeft > 0
          ? `Внимание! Доступны 5 бесплатных проверок в месяц.`
          : ""}

        {attemptsLeft === 0 &&
          `Проверка недоступна! Вы исчерпали лимит. Следующая проверка будет
          доступна ${upcomingDate}`}
      </Typography>
    </Box>
  );
}
