import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTelegram } from "../../hooks/useTelegram";
import { Title, Button } from "../../components/atoms";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AttemptMessage } from "../../components/aml/";
import { AmlVerificationResponse } from "../../components/aml/";
import { useMutation, useQuery } from "react-query";
import { getAmlQuota } from "../../services/aml/quota";
import { AmlCheckWallet } from "../../services/aml/check-wallet";
import { useState } from "react";
import { RisksModal } from "../../components/modals";
interface AMLFormTypes {
  wallet: string;
}

export function AMLVerification() {
  const [openRiskModal, setOpenRiskModal] = useState<boolean>(false);
  const [isShownAmlResult, setIsShownAmlResult] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { tg } = useTelegram();
  tg.BackButton.show();

  const amlSchema = yup.object().shape({
    wallet: yup
      .string()
      .matches(
        /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
        "Введите правильно адрес кошелька. "
      )
      .required("Поле с адресом кошелька нужно заполнить"),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AMLFormTypes>({
    defaultValues: {
      wallet: "",
    },
    resolver: yupResolver<AMLFormTypes>(amlSchema),
  });

  // GET ATTEMPTS LEFT AND UPCOMING ATTEMPTS RESTART DATE
  const { data, isLoading } = useQuery({
    queryKey: "aml-quota",
    queryFn: () => getAmlQuota(),
    retry: false,
    refetchOnWindowFocus: false,
  });

  // MUTAION FOR AML CHECKING
  const {
    mutate,
    data: amlResultData,
    isLoading: walletCheckingLoading,
  } = useMutation({
    mutationKey: "aml-check",
    mutationFn: AmlCheckWallet,
    onSuccess(data) {
      setIsShownAmlResult(true);
      if (data.data.risks_list.length > 0) {
        setSearchParams({ "error-background": "true" }); // Making background red
      }
    },
    onError() {
      setIsShownAmlResult(false);
      navigate("/failed-aml-check?error-background=true");
    },
  });

  const onSubmit: SubmitHandler<AMLFormTypes> = (data) => {
    const { wallet } = data;

    mutate({ trc20_wallet: wallet });
  };

  if (isLoading || walletCheckingLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={45} />
      </Box>
    );
  }

  return (
    <>
      {!openRiskModal && (
        <>
          <Title>AML Проверка</Title>
          {!isShownAmlResult && (
            <>
              <Typography sx={{ fontWeight: "600" }}>
                Введите кошелек для проверки
              </Typography>

              <TextField
                disabled={data?.data.count === 0 || !data?.data.count}
                FormHelperTextProps={{
                  sx: {
                    color: errors.wallet ? "error.main" : "#487768 !important",
                  },
                }}
                error={Boolean(errors.wallet?.message)}
                helperText={
                  errors.wallet?.message ||
                  `Осталось проверок: ${data?.data.count}`
                }
                {...register("wallet")}
                required
                sx={{
                  width: "100%",
                  color: "#fff",
                  marginTop: "1rem",
                  borderBottom: "#487768",
                }}
                label="Адрес кошелька TRC-20 (34 символа)"
              />

              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  width: "100%",
                  borderRadius: "10px",
                  marginTop: "1.75rem",
                  color: "#FFE500",
                }}
              >
                <InfoOutlinedIcon
                  sx={{ fontSize: "1.25rem", color: "#FFE500" }}
                />
                <AttemptMessage
                  upcomingRestartDate={data?.data.upcoming_check_date}
                  attemptsLeft={data?.data.count}
                />
              </Box>
              <Button
                onClick={handleSubmit(onSubmit)}
                disabled={
                  watch("wallet").length === 0 ||
                  data?.data.count === 0 ||
                  !data?.data.count
                }
                sx={{
                  marginTop: "1.25rem",
                  backgroundImage:
                    "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
                  ":hover": {
                    background: "unset",
                    backgroundImage:
                      "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
                  },
                }}
              >
                Проверить кошелек
              </Button>
            </>
          )}
          {amlResultData && isShownAmlResult && (
            <AmlVerificationResponse
              openModal={() => setOpenRiskModal(true)}
              {...amlResultData.data}
            />
          )}

          <Button
            onClick={() => {
              navigate("/");
              tg.BackButton.hide();
            }}
            sx={{
              border: searchParams.get("error-background")
                ? "1px solid #472B2B"
                : "1px solid rgb(0 255 173 / 10%)",

              marginTop: "1rem",
              backgroundImage: searchParams.get("error-background")
                ? "radial-gradient(50% 223.68% at 50% 50%, rgba(71, 43, 43, 0.5) 0%, rgba(71, 43, 43, 0) 100%)"
                : "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
              ":hover": {
                background: "unset",
                backgroundImage:
                  "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
              },
            }}
          >
            Перейти на главный экран
          </Button>
        </>
      )}

      <RisksModal
        risksList={amlResultData?.data.risks_list}
        open={openRiskModal}
        onClose={() => setOpenRiskModal(false)}
      />
    </>
  );
}
