import { useNavigate } from "react-router-dom";
import { Title, Button } from "../../components/atoms";
import { useTelegram } from "../../hooks/useTelegram";
import { Box, Link, Typography } from "@mui/material";

export function Support() {
  const navigate = useNavigate();
  const { tg } = useTelegram();
  tg.BackButton.show();

  return (
    <>
      <Title>Поддержка</Title>
      <Box sx={{ display: "grid", gap: "1rem" }}>
        <Typography sx={{ fontSize: 15 }}>
          Наши операторы на связи 24/7. Готовы ответить на любые вопросы.
        </Typography>
        <Typography sx={{ fontSize: 15 }}>
          Для получения пропуска в наш офис и покупки USDT, вам нужно создать
          заявку через бота.{" "}
        </Typography>
        <Typography sx={{ fontSize: 15 }}>
          Для связи с нами напишите —{" "}
          <Link
            sx={{
              color: "#fff",
              textDecorationColor: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              tg.openTelegramLink("https://t.me/Mosca67_Support");
              tg.close();
            }}
          >
            @Mosca67_Support
          </Link>
        </Typography>
      </Box>
      <Button
        onClick={() => {
          tg.openTelegramLink("https://t.me/Mosca67_Support");
          tg.close();
        }}
        sx={{
          height: "48px",
          marginTop: "1.75rem",
          backgroundImage:
            "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          ":hover": {
            background: "unset",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          },
        }}
      >
        Чат
      </Button>
      <Button
        onClick={() => {
          navigate("/");
          tg.BackButton.hide();
        }}
        sx={{
          marginTop: "1rem",
          backgroundImage:
            "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          ":hover": {
            background: "unset",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
          },
        }}
      >
        Перейти на главный экран
      </Button>
    </>
  );
}
