import { Button as MuiButton, ButtonProps } from "@mui/material";

interface IButtonProps extends ButtonProps {}

export const Button: React.FC<IButtonProps> = (props) => {
  return (
    <MuiButton
      variant="text"
      {...props}
      sx={{
        width: "100%",
        padding: "10px 5px",
        textTransform: "none",
        fontSize: "15px",
        fontWeight: "400",
        borderRadius: "10px",
        color: "#fff",
        border: props.disabled
          ? "1px solid rgb(0 255 173 / 4%)"
          : "1px solid rgb(0 255 173 / 10%)",
        background: "rgb(0 255 173 / 0%)",
        ":hover": {
          background: "rgb(0 255 173 / 10%)",
        },
        ...props.sx,
      }}
    />
  );
};
