const tg = Telegram.WebApp;

export const useTelegram = () => {
  const user = tg.initDataUnsafe.user;
  const platform = tg.platform;
  return {
    tg,
    user,
    platform,
  };
};
