import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#00CB8A",
    },
    action: {
      disabled: "#487768",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 13.25,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.25,
        },
      },
    },
    MuiModal: {
      defaultProps: {
        disableAutoFocus: true,
        slotProps: {
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
          },
        },
      },
      styleOverrides: {
        root: {
          zIndex: 99999,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInput-root": {
            "&:before": {
              content: '""',
              width: "100%",
              height: "1px",
              background:
                "linear-gradient(90deg, #487768 0%, rgb(43 71 62 / 14%) 100%)",
              borderBottom: "none",
              borderRadius: "20px",
            },
            "&:after": {
              height: "1px",
              background:
                "linear-gradient(90deg, rgba(43, 71, 62, 0) 0%, #00ffad 50%, rgba(43, 71, 62, 0) 100%);",
              borderBottom: "none !important",
              transformOrigin: "left",
              borderRadius: "20px",
            },
          },
          "& .MuiInput-root.Mui-disabled": {
            "&:before": {
              content: '""',
              width: "100%",
              height: "1px",
              background:
                "linear-gradient(90deg, #487768 0%, rgb(43 71 62 / 14%) 100%)",
              borderBottom: "none",
              borderRadius: "20px",
            },
            "&:after": {
              height: "1px",
              background:
                "linear-gradient(90deg, rgba(43, 71, 62, 0) 0%, #00ffad 50%, rgba(43, 71, 62, 0) 100%);",
              borderBottom: "none !important",
              transformOrigin: "left",
              borderRadius: "20px",
            },
          },
          "& .MuiInput-root:hover": {
            "&:before": {
              border: "none !important",
            },
          },
        },
      },
      defaultProps: {
        variant: "standard",
        InputLabelProps: {
          style: {
            color: "#fff",
          },
        },
        inputProps: {
          style: {
            fontSize: 16,
            color: "#fff",
          },
        },
      },
    },
  },
});
