import { Box, Fade, Modal, Typography } from "@mui/material";
import { Title, Button } from "../../atoms";

interface RulesModalProps {
  open: boolean;
  onClose: () => void;
}

export function RulesModal({ open, onClose }: RulesModalProps) {
  return (
    <Modal open={open} onClose={onClose} sx={{ overflow: "scroll" }}>
      <Fade in={open}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "375px",
              margin: "0 auto",
              padding: "10px",
            }}
          >
            <Title sx={{ paddingBottom: "0rem" }}>Правила</Title>
            <Box>
              <Typography
                sx={{ fontWeight: "700", padding: "1rem 0 .75rem 0" }}
              >
                Покупка
              </Typography>
              <Box sx={{ display: "grid", gap: ".25rem" }}>
                <Typography sx={{ fontSize: 13 }}>
                  1. Покупка USDT осуществляется у лиц строго старше 18 лет.
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  2. Запрещено продавать USDT для третьих лиц.
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  3. При продаже при себе иметь паспорт.
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  4. При первой продаже необходимо пройти верификацию.
                </Typography>
              </Box>
              <Typography sx={{ fontWeight: "700", paddingBlock: "1rem" }}>
                Продажа
              </Typography>
              <Box sx={{ display: "grid", gap: ".25rem" }}>
                <Typography sx={{ fontSize: 13 }}>
                  1. Покупка USDT осуществляется у лиц строго старше 18 лет.
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  2. Запрещено продавать USDT для третьих лиц.
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  3. При продаже при себе иметь паспорт.
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  4. При первой продаже необходимо пройти верификацию.
                </Typography>
              </Box>

              <Box>
                <Typography sx={{ fontSize: 13, paddingBlock: ".5rem" }}>
                  Минимальная сумма 300 USDT, платежи меньшей этой суммы
                  обработаны не будут.
                </Typography>
                <Typography sx={{ fontSize: 13, paddingBlock: ".5rem" }}>
                  Максимальная сумма 100 000 USDT, платежи больше этой суммы
                  обработаны не будут
                </Typography>
              </Box>

              <Typography sx={{ fontSize: 13, paddingBlock: ".5rem" }}>
                Мы принимаем USDT, только в сети TRC-20.
              </Typography>
              <Typography sx={{ fontSize: 13, paddingBlock: ".5rem" }}>
                Будьте внимательны, мы не несем ответственности за USDT,
                отправленные не в сети блокчейна TRON.
              </Typography>
              <Typography sx={{ fontSize: 13, paddingBlock: ".5rem" }}>
                При зачисление USDT к нам на баланс, будет произведен
                автоматический обмен по текущему курсу.
              </Typography>
              <Typography sx={{ fontSize: 13, paddingBlock: ".5rem" }}>
                Срок зачисления USDT в сети TRON занимает от 3 до 10 минут.
              </Typography>
            </Box>
            <Button
              onClick={(e) => {
                onClose();
              }}
              sx={{
                marginTop: "1rem",
                backgroundImage:
                  "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
                ":hover": {
                  background: "unset",
                  backgroundImage:
                    "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
                },
              }}
            >
              Назад
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
