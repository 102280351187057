import { api } from "../../api";
import { buyUsdtTypes } from "./interface";

export async function buyUsdt(data: buyUsdtTypes) {
  const access_token = localStorage.getItem("access_token") || "";
  const res = await api.post("/orders/sell_usdt", data, {
    headers: {
      "access-token": access_token,
    },
  });
  return res;
}
