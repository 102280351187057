import { Box } from "@mui/material";

import logo from "../images/logo.svg";
import { Button } from "../components/atoms";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export function Home() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.delete("error-background");
    setSearchParams(searchParams);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "120px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: "999",
            pointerEvents: "none",
          }}
          component="img"
          src={logo}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "30px",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={() => navigate("/exchange")}
          sx={{
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            ":hover": {
              background: "unset",
              backgroundImage:
                "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            },
          }}
        >
          Купить / Продать USDT
        </Button>

        <Box sx={{ display: "grid", gap: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={() => navigate("/valuation")}
              sx={{
                width: "50%",
                ":hover": {
                  background: "#00FFAD0D",
                },
              }}
            >
              Курс
            </Button>
            <Button onClick={() => navigate("/requests")} sx={{ width: "50%" }}>
              Все заявки
            </Button>
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button onClick={() => navigate("/support")} sx={{ width: "50%" }}>
              Поддержка
            </Button>
            <Button onClick={() => navigate("/about")} sx={{ width: "50%" }}>
              О нас
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: "grid", gap: "10px" }}>
          <Button
            onClick={() => navigate("/aml-verification")}
            sx={{
              width: "100%",
              ":hover": {
                background: "#00FFAD0D",
              },
            }}
          >
            AML проверка
          </Button>
          <Button
            onClick={() => navigate("/referral-program")}
            sx={{ width: "100%" }}
          >
            Реферальная программа
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
