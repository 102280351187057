import { api } from "../../api";
import { History } from "./interface";


export async function getRequestsHistory() {
  const access_token = localStorage.getItem("access_token") || "";

  const res = await api.get<History>("/orders/history", {
    headers: {
      "access-token": access_token,
    },
  });

  return res;
}
