import { api } from "../api";

export async function getCurrencyRate(access_token: string) {
  const res = await api.get("/rate/", {
    headers: {
      "access-token": access_token,
    },
  });
  return res;
}
