import { Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { SuccessfullRequestIcon } from "../../../icons";
import { Button } from "../../../components/atoms";

export function SuccessfullSoldReq() {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const navigate = useNavigate();
  const [searchParamas] = useSearchParams();
  const orderId = searchParamas.get("order-id");
  const trcWallet = searchParamas.get("trc-wallet");

  // Handle Copying Wallet FUnction
  function handleClickToCopy() {
    navigator.clipboard.writeText(trcWallet || "trc-empty");
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 2000);
  }

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SuccessfullRequestIcon />
        <Typography
          sx={{
            fontSize: "21px",
            fontWeight: "700",
            textTransform: "uppercase",
            textAlign: "center",
            paddingBottom: "10px",
            paddingTop: "1.25rem",
          }}
        >
          Заявка создана
        </Typography>
        <Typography>Номер заявки на продажу: {orderId}.</Typography>
        <Typography sx={{ textAlign: "center" }}>
          Адрес кошелька: {trcWallet}
        </Typography>
        <Typography sx={{ paddingTop: "1.25rem", textAlign: "center" }}>
          Ваш кошелек для перевода указан выше. Необходимо перевести на него
          USDT (TRC-20)
        </Typography>

        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ fontSize: 13, paddingBlock: ".5rem" }}>
            Минимальная сумма 300 USDT, платежи меньшей этой суммы обработаны не
            будут.
          </Typography>
          <Typography sx={{ fontSize: 13, paddingBlock: ".5rem" }}>
            Максимальная сумма 100 000 USDT, платежи больше этой суммы
            обработаны не будут.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          borderRadius: "10px",
          padding: "10px 20px",
          marginTop: "1.75rem",
          color: "#FFE500",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "10px",
          }}
        >
          Внимание!
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "10px",
            paddingBottom: ".75rem",
          }}
        >
          Если в течение 20 минут вам не пришло сообщение в Боте о зачислении,
          напишите в поддержку.
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "10px",
          }}
        >
          Время жизни кошелька 30 минут. Спустя 30 минут кошелёк будет
          недоступен.
        </Typography>
      </Box>

      <Box>
        <Button
          onClick={handleClickToCopy}
          sx={{
            marginTop: "2.75rem",
            backgroundImage: isCopied
              ? "none"
              : "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            backgroundColor: isCopied ? "rgba(0, 255, 173, 0.5)" : "unset",
            transition: "all .4s ease",
            ":hover": {
              background: isCopied ? "rgba(0, 255, 173, 0.5)" : "unset",
              backgroundImage: isCopied
                ? "none"
                : "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            },
          }}
        >
          {isCopied ? "Адрес скопирован!" : "Копировать адрес кошелька"}
        </Button>

        <Button
          onClick={() => {
            navigate("/");
          }}
          sx={{
            marginTop: "1rem",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            ":hover": {
              background: "unset",
              backgroundImage:
                "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            },
          }}
        >
          Перейти на главный экран
        </Button>
      </Box>
    </Box>
  );
}
