import { api } from "../../api";
import { sellUsdtTypes } from "./interface";

export async function sellUsdt(data: sellUsdtTypes) {
  const access_token = localStorage.getItem("access_token") || "";
  const res = await api.post("/orders/buy_usdt", data, {
    headers: {
      "access-token": access_token,
    },
  });
  return res;
}
