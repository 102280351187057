import {
  Box,
  CircularProgress,
  Link,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "./atoms";
import { SubmitHandler, useForm } from "react-hook-form";
import { ChangeEvent, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useMutation } from "react-query";
import { useTelegram } from "../hooks/useTelegram";

import { buyUsdt } from "../services/orders/buy-usdt";
import { sellUsdt } from "../services/orders/sell-usdt";

interface ExchangeFormProps {
  tab: string;
  toggleRulesModal: () => void;
}
export interface Inputs {
  first_name: string;
  last_name: string;
  patronymic: string;
  trc20_wallet: string;
  agreedToRules: boolean;
  amount: string;
}

export function ExchangeForm({ tab, toggleRulesModal }: ExchangeFormProps) {
  const { user } = useTelegram();
  const navigate = useNavigate();

  // Handle Input Cursor Transition with Keaboard
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);
  const input5Ref = useRef<HTMLInputElement>(null);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    nextInputRef: React.RefObject<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter key behavior
      if (nextInputRef.current) {
        nextInputRef.current.focus();
      }
    }
  };

  const [policy, setPolicy] = useState<boolean>(false);

  // Buy Validation Schema
  const buySchema = yup.object().shape({
    first_name: yup
      .string()
      .matches(/^\D*$/, "Имя должно состоять только из букв.")
      .required("Имя должно быть заполнено"),
    last_name: yup
      .string()
      .matches(/^\D*$/, "Фамилия должна состоять только из букв.")
      .required("Фамилия должна быть заполнена."),
    patronymic: yup
      .string()
      .matches(/^\D*$/, "Отчество должно состоять только из букв."),
    amount: yup.number().required("Введите сумму на покупку USDT"),
    trc20_wallet: yup
      .string()
      .matches(
        /^T[1-9A-HJ-NP-Za-km-z]{33}$/, // Validation pattern for trc20 wallets
        "Введите правильно адрес кошелька. "
      )
      .required("Поле с адресом кошелька нужно заполнить"),
  });

  // Sell Validation Schema
  const sellSchema = yup.object().shape({
    first_name: yup
      .string()
      .matches(/^\D*$/, "Имя должно состоять только из букв.")
      .required("Имя должно быть заполнено"),
    last_name: yup
      .string()
      .matches(/^\D*$/, "Фамилия должна состоять только из букв.")
      .required("Фамилия должна быть заполнена."),
    patronymic: yup.string(),
  });

  // React Hook Form
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver<any>(tab === "1" ? buySchema : sellSchema),
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      patronymic: "",
      trc20_wallet: "",
      agreedToRules: policy,
      amount: undefined,
    },
  });

  // FORMATTING INPUT NUMBER
  function formatNumberWithSpaces(value: string) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/\s+/g, ""); // Remove existing spaces
    const formattedValue = formatNumberWithSpaces(inputValue);
    setValue("amount", formattedValue, { shouldValidate: true }); // Update form state
  };

  // BUY USDT REQUEST
  const { mutate: buyUsdtMutaion, isLoading: isBuyUsdtMutaionLoading } =
    useMutation({
      mutationKey: "sell-usdt",
      mutationFn: buyUsdt,
      onSuccess(data) {
        const firstName = getValues("first_name");
        const lastName = getValues("last_name");
        const patronymic = getValues("patronymic");

        navigate(
          `/successfull-buy-req/?order-id=${data.data.order_id}&first-name=${firstName}&last-name=${lastName}&patronymic=${patronymic}`
        );
      },
      onError(e) {
        console.log(e);
        navigate("/failed-exchange-req?error-background=true");
      },
    });

  // SELL USDT MUTATION
  const { mutate: sellUsdtMutation, isLoading: isSellUsdtMutaionLoading } =
    useMutation({
      mutationKey: "sell-usdt",
      mutationFn: sellUsdt,
      onSuccess(data) {
        navigate(
          `/successfull-sold-req/?order-id=${data.data.order_id}&trc-wallet=${data.data.wallet}`
        );
      },
      onError(e) {
        console.log(e);
        navigate("/failed-exchange-req?error-background=true");
      },
    });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    // MAKING SURE THAT AMOUNT TURNS INTO STRING AND GETS FORMATTED BEFORE SENDING REQUEST
    const amountStr = String(data.amount).replace(/\s+/g, "");

    const buyUsdtData = {
      username: user?.username,
      first_name: data.first_name,
      last_name: data.last_name,
      patronymic: data.patronymic,
      amount: Number(amountStr),
      trc20_wallet: data.trc20_wallet,
    };

    const sellUsdtData = {
      username: user?.username,
      first_name: data.first_name,
      last_name: data.last_name,
      patronymic: data.patronymic,
    };

    if (tab === "1") {
      buyUsdtMutaion(buyUsdtData);
    }

    if (tab === "2") {
      sellUsdtMutation(sellUsdtData);
    }
  };

  if (isBuyUsdtMutaionLoading || isSellUsdtMutaionLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={35} />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: "grid", gap: "12px" }}>
        {tab === "1" && (
          <>
            <Typography sx={{ textAlign: "center", paddingTop: "1.5rem" }}>
              Введите сумму в рублях
            </Typography>
            <TextField
              type="tel"
              InputProps={{
                inputProps: {
                  min: 0,
                  style: { fontSize: 36, color: "#fff", textAlign: "center" },
                },
              }}
              autoFocus
              placeholder="0"
              error={Boolean(errors.amount?.message)}
              {...register("amount", {
                onChange: handleChange,
              })}
              sx={{
                background:
                  "radial-gradient(31.07% 94.07% at 49.82% 100%, #2B473E 0%, rgba(43, 71, 62, 0) 100%)",
                "& .MuiInput-root": {
                  "&:before": {
                    borderBottom: "none",
                    height: "1px",
                    background:
                      "linear-gradient(90deg, rgba(43, 71, 62, 0) 0%, #00CB8A 50%, rgba(43, 71, 62, 0) 100%);",
                  },
                  "&:after": {
                    height: "1px",
                    background:
                      "linear-gradient(90deg, rgba(43, 71, 62, 0) 0%, #00ffad 50%, rgba(43, 71, 62, 0) 100%);",
                    borderBottom: "none",
                    borderRadius: "20px",
                    transformOrigin: "center !important",
                  },
                  "& .MuiInput-root:hover": {
                    "&:before": {
                      border: "none",
                    },
                  },
                },
              }}
              inputRef={input1Ref}
              onKeyDown={(e) =>
                handleKeyDown(
                  e as React.KeyboardEvent<HTMLInputElement>,
                  input2Ref
                )
              }
            />
          </>
        )}

        <Typography
          sx={{
            fontWeight: "500",
            textAlign: "left",
            paddingTop: tab === "1" ? ".5rem" : "1.25rem",
          }}
        >
          Данные:
        </Typography>
        <TextField
          error={Boolean(errors.last_name?.message)}
          helperText={errors.last_name?.message || " "}
          {...register("last_name")}
          required
          sx={{ width: "100%", color: "#fff", height: "50px" }}
          label="Фамилия"
          inputRef={input2Ref}
          onKeyDown={(e) =>
            handleKeyDown(e as React.KeyboardEvent<HTMLInputElement>, input3Ref)
          }
        />

        <TextField
          error={Boolean(errors.first_name?.message)}
          helperText={errors.first_name?.message || " "}
          {...register("first_name")}
          required
          sx={{ width: "100%", color: "#fff", height: "50px" }}
          label="Имя"
          inputRef={input3Ref}
          onKeyDown={(e) =>
            handleKeyDown(e as React.KeyboardEvent<HTMLInputElement>, input4Ref)
          }
        />

        <TextField
          error={Boolean(errors.patronymic?.message)}
          helperText={errors.patronymic?.message || " "}
          {...register("patronymic", {
            required: "Заполните имя правильно.",
          })}
          sx={{ width: "100%", color: "#fff", height: "50px" }}
          label="Отчество"
          inputRef={input4Ref}
          onKeyDown={(e) =>
            handleKeyDown(e as React.KeyboardEvent<HTMLInputElement>, input5Ref)
          }
        />

        {tab === "1" && (
          <TextField
            error={Boolean(errors.trc20_wallet?.message)}
            helperText={errors.trc20_wallet?.message || " "}
            {...register("trc20_wallet")}
            required
            sx={{ width: "100%", color: "#fff", height: "50px" }}
            inputRef={input5Ref}
            label="Адрес кошелька TRC-20 (34 символа)"
          />
        )}

        <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Radio
            checkedIcon={<TaskAltIcon />}
            sx={{ color: "#fff" }}
            {...register("agreedToRules")}
            size="small"
            checked={policy}
            onClick={() => {
              setPolicy((prev) => !prev);
            }}
          />
          <Typography sx={{ fontSize: "10px", fontWeight: "200" }}>
            Я прочитал{" "}
            <Box
              component="span"
              onClick={toggleRulesModal}
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "10px",
                color: "#fff",
                ":hover": {
                  color: "primary.main",
                },
              }}
            >
              правила
            </Box>{" "}
            и принимаю условия сервиса
          </Typography>
        </Box>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={
            !policy || isBuyUsdtMutaionLoading || isSellUsdtMutaionLoading
          }
          sx={{
            marginTop: ".1rem",
            backgroundImage:
              "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            ":hover": {
              background: "unset",
              backgroundImage:
                "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
            },
          }}
        >
          {tab === "1" ? "Создать заявку" : "Получить одноразовый кошелек"}
        </Button>
      </Box>
    </>
  );
}
