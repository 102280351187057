import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../hooks/useTelegram";

import { Title, Button } from "../../components/atoms";
import { Box, CircularProgress, Typography } from "@mui/material";

import { useQuery } from "react-query";
import { getRequestsHistory } from "../../services/orders/history";
import { Fragment, useState } from "react";
import { History, Order } from "../../services/orders/history/interface";
import { ApplicationCard } from "../../components/molecules";

export function HistoryPage() {
  const [historyArray, setHistoryArray] = useState<History | undefined>();

  const navigate = useNavigate();
  const { tg } = useTelegram();
  tg.BackButton.show();

  // Fetch the JSON file from the public folder
  // const [animationData, setAnimationData] = useState<any>(null);
  // useEffect(() => {
  //   fetch(`${process.env.PUBLIC_URL}/loader.json`)
  //     .then((response) => response.json())
  //     .then((data) => setAnimationData(data))
  //     .catch((error) => console.error("Error loading animation data:", error));
  // }, []);

  // Fetching History Data
  const { isLoading } = useQuery({
    queryKey: "request-history",
    queryFn: getRequestsHistory,
    onSuccess(data) {
      setHistoryArray(data.data);
    },
    // retry: false,
  });

  const sortAndGroupByDate = (orders: Order[]): Record<string, Order[]> => {
    // Sorting orders by date
    const sortedOrders = [...orders].sort(
      (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
    );

    // Grouping orders by date
    return sortedOrders.reduce((acc: Record<string, Order[]>, order: Order) => {
      const date = new Date(order.datetime).toLocaleDateString("ru-RU", {
        weekday: "long",
        day: "numeric",
        month: "long",
      });

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(order);

      return acc;
    }, {});
  };

  const groupedOrders = historyArray
    ? sortAndGroupByDate(historyArray.orders)
    : [];

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={45} />
          {/* <Lottie animationData={animationData} loop /> */}
        </Box>
      ) : (
        <>
          <Title>Все заявки</Title>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            {historyArray?.orders.length === 0 && (
              <Typography sx={{ textAlign: "center" }}>
                У вас пока нет заявок.
              </Typography>
            )}
            {Object.entries(groupedOrders).map(([date, orders], i) => (
              <Fragment key={`${date}-${i}`}>
                <Typography
                  sx={{
                    color: "#487768",
                    alignSelf: "flex-end",
                    textTransform: "capitalize",
                  }}
                >
                  {date}
                </Typography>
                {orders.map((order, index) => (
                  <ApplicationCard
                    key={`${order.order_id}-${index}`}
                    orderData={order}
                  />
                ))}
              </Fragment>
            ))}
          </Box>

          <Button
            onClick={() => {
              navigate("/");
              tg.BackButton.hide();
            }}
            sx={{
              marginTop: "1.75rem",
              backgroundImage:
                "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
              ":hover": {
                background: "unset",
                backgroundImage:
                  "radial-gradient(50% 223.68% at 50% 50%, rgba(43, 71, 62, 0.5) 0%, rgba(43, 71, 62, 0) 100%)",
              },
            }}
          >
            Перейти на главный экран
          </Button>
        </>
      )}
    </>
  );
}
